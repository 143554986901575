<template>
  <div class="card-body mt-2 white">
    <div class="body-text" v-if="historys.length > 0">
      <template v-for="history in historys" :key="history">
        <div class="p-item">
          <div class="image">
            <img :src="history.pictureUrl" class="appeal-image-logo" alt="" />
          </div>
          <div class="text ml">
            {{ history.displayName }} <br />{{ dateThai(history.created_at) }}
          </div>
          <img :src="newLogo" v-if="!history.friendFlag" class="new" alt="" />
        </div>
      </template>
    </div>
    <div class="body-text" v-else>
      <template>
        <div class="card-body mt-3 white">
          <div class="history-empty">
            <div class="history-empty-text">ยังไม่มีข้อมูล</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import useRegister from "@/service/api/register";
import newLogo from "@/assets/new.png";
import moment from "moment";
export default defineComponent({
  name: "historys-Popular",
  setup() {
    const { History } = useRegister();
    const historys = ref([]);
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      History().then((res) => {
        historys.value = res.data.data;
        isLoading.value = false;
      });
    });
    function dateThai(date) {
      let y = moment(date).format("yyy");
      let year = parseInt(y) + 543;
      let march = moment(date).locale("th");

      return march.format("D - MMM - " + year + " เวลา HH : mm");
    }

    return {
      historys,
      isLoading,
      color,
      newLogo,
      dateThai,
    };
  },
});
</script>
<style scoped>
.card-body {
  padding: 0;
}

.new {
  margin-left: auto;
  width: 30px;
  height: 30px;
}
.ml {
  padding: 10px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.p-item {
  display: flex;
  padding: 10px;
  height: 5rem;
  border-bottom: 1.3px solid #dee2e6;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
</style>

<template>
  <div style="padding: 15px" class="text-center mt-5">
    <QRCodeVue3
      :value="`line://app/${LinkApp}?by=${Profire.id_line}`"
      :image="icon"
      :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
      :dotsOptions="{
        type: 'square',
        color: '#000000',
        gradient: {
          type: 'linear',
          rotation: 0,
          colorStops: [
            { offset: 0, color: '#000000' },
            { offset: 1, color: '#000000' },
          ],
        },
      }"
      :cornersSquareOptions="{
        type: 'extra-rounded',
        color: '#000000',
      }"
      :cornersDotOptions="{
        type: 'dot',
        color: '#000000',
      }"
    />
    <div class="text-center name">
      {{ Profire.firstname }} {{ Profire.lastname }}
    </div>
  </div>
</template>

<script>
import icon from "@/assets/logo.png";
import { defineComponent, inject } from "vue";
import QRCodeVue3 from "qrcode-vue3";
export default defineComponent({
  name: "QRCode",
  components: {
    QRCodeVue3,
  },
  setup() {
    const store = inject("store");
    const Profire = store.getters.getProfire;
    const LinkApp = process.env.VUE_APP_LIFF_REGISTER;
    return { Profire, icon ,LinkApp};
  },
});
</script>

<style scoped>
.name {
  padding: 10px;
  font-size: 20px;
  color: #1a446b;
  font-weight: bold;
}
</style>

<template>
  <div class="row">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
      loader="dots"
    />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">ข้อมูลที่พักอาศัย</div>
        </div>
        <Form
          @submit="onSubmitRegister"
          :validation-schema="register"
          id="kt_account_profile_details_form"
          class="form"
          novalidate="novalidate"
        >
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <!--begin::Input group-->
                  <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6"
                      >บ้านเลขที่</label
                    >
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <Field
                        type="text"
                        name="numberhome"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="บ้านเลขที่"
                        v-model="data_register.numberhome"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="numberhome" />
                        </div>
                      </div>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-6">
                  <!--begin::Input group-->
                  <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6"
                      >หมู่</label
                    >
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <Field
                        type="text"
                        name="moo"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="หมู่"
                        v-model="data_register.moo"
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
              </div>

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >ตรอก/ซอย</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="alleyway"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ตรอก/ซอย"
                    v-model="data_register.alleyway"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="alleyway" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">ถนน</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="road"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ถนน"
                    v-model="data_register.road"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="road" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >จังหวัด</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="province"
                    class="form-control form-control-lg form-control-solid"
                    as="select"
                    v-model="data_register.province"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option
                      v-for="(item, key) in uniqueProvince"
                      :key="key"
                      :value="key"
                    >
                      {{ item.province }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="province" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >อำเภอ/เขต</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="amphoe"
                    class="form-control form-control-lg form-control-solid"
                    as="select"
                    v-model="data_register.amphoe"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option
                      v-for="(item, key) in uniqueAmphoe"
                      :key="key"
                      :value="key"
                    >
                      {{ item.amphoe }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="amphoe" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ตำบล/แขวง</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="district"
                    class="form-control form-control-lg form-control-solid"
                    as="select"
                    v-model="data_register.district"
                    @change="setZipcode(data_register.district)"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option
                      v-for="(item, key) in uniqueDistrict"
                      :key="key"
                      :value="key"
                    >
                      {{ item.district }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="district" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >รหัสไปรษณีย์</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="zipcode"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="รหัสไปรษณีย์"
                    v-model="data_register.zipcode"
                    disabled
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="zipcode" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  แก้ไขข้อมูล
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import json from "@/service/DataStore.json";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { UpdateUser } = useRegister();
    const items = ref(json);
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const data_register = store.getters.getRegister;

    const register = Yup.object().shape({
      numberhome: Yup.string()
        .required("กรุณากรอก บ้านเลขที่")
        .label("บ้านเลขที่"),
      moo: Yup.string().label("หมู่").nullable(),
      alleyway: Yup.string().label("ตรอก/ซอย").nullable(),
      road: Yup.string().label("ถนน").nullable(),
      province: Yup.string().required("กรุณาเลือก จังหวัด").label("จังหวัด"),
      amphoe: Yup.string().required("กรุณาเลือก อำเภอ/เขต").label("อำเภอ/เขต"),
      district: Yup.string()
        .required("กรุณาเลือก ตำบล/แขวง")
        .label("ตำบล/แขวง"),
      zipcode: Yup.string()
        .required("กรุณากรอก รหัสไปรษณีย์")
        .label("รหัสไปรษณีย์"),
    });

    const uniqueProvince = computed(() => {
      return items.value.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.province]: current,
        });
      }, {});
    });

    const uniqueAmphoe = computed(() => {
      let dd = items.value.filter(
        (item) => item.province === data_register.province
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.amphoe]: current,
        });
      }, {});
    });

    const uniqueDistrict = computed(() => {
      let dd = items.value.filter(
        (item) => item.amphoe === data_register.amphoe
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.district]: current,
        });
      }, {});
    });

    function setZipcode(val) {
      let result = val;

      let data = items.value.filter(
        (item) =>
          item.district === result && item.amphoe === data_register.amphoe
      );
      data_register.zipcode = data[0].zipcode;
    }

    const back = () => {
      router.push({ name: "Edit" });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      store.dispatch(SET_REGISTER, values);

      Sconfirm("ยืนยัน การแก้ไขข้อมูล", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await UpdateUser(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("แก้ไขข้อมูลสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    return {
      back,
      onSubmitRegister,
      register,
      data_register,
      isLoading,
      color,
      uniqueProvince,
      uniqueAmphoe,
      uniqueDistrict,
      setZipcode,
    };
  },
});
</script>

<style scoped>
.icon {
  width: 50px;
  padding: 5px;
}
.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
}
input[type="radio"] {
  display: none;
}
input:checked + span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
